//variables :
$color__primary--dark: #0175be;
$color__primary--medium: #a4d5f3;
$color__bg--input: rgb(168, 168, 168);
$color__fontInputPlaceholder: $color__bg--input;
$color--error: #be3131;

$select--main-color: $color__primary--dark;
$select__tag--icon: darken($color__primary--dark, 25);
$select__tag--icon-hover: #35495e;
$select--highlight: $color__primary--dark;
$select__font--bg-light: #35495e;
$select__font--bg-dark: #fff;
$select__bg--selected: #f3f3f3;
$select__font--highlight: #fff;
$select--font-size: inherit;
$select__tag--fontsize: inherit;

// modified scss

.filtering-controls {
  .multiselect__element {
    background: red !important;
    span {
      overflow-wrap: break-word !important;
      line-height: 1.2;
      span {
        padding-right: 5%;
      }
    }
  }

  span.multiselect__single {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

// Orginal CSS

fieldset[disabled] .multiselect {
  pointer-events: none;
}

// SPINNER
.multiselect__spinner {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 48px;
  height: 35px;
  background: #fff;
  display: block;
}
.multiselect__spinner:after,
.multiselect__spinner:before {
  position: absolute;
  content: '';
  top: 50%;
  left: 50%;
  margin: -8px 0 0 -8px;
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 2px solid transparent;
  border-top-color: $select--main-color;
  box-shadow: 0 0 0 1px transparent;
}
.multiselect__spinner:before {
  animation: spinning 2.4s cubic-bezier(0.41, 0.26, 0.2, 0.62);
  animation-iteration-count: infinite;
}
.multiselect__spinner:after {
  animation: spinning 2.4s cubic-bezier(0.51, 0.09, 0.21, 0.8);
  animation-iteration-count: infinite;
}
.multiselect__loading-enter-active,
.multiselect__loading-leave-active {
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}
.multiselect__loading-enter,
.multiselect__loading-leave-active {
  opacity: 0;
}
@keyframes spinning {
  0% {
    transform: rotate(0);
  }
  to {
    transform: rotate(2turn);
  }
}

//INPUTS
.multiselect,
.multiselect__input,
.multiselect__single {
  font-family: inherit;
  font-size: $select--font-size;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}
.multiselect {
  box-sizing: content-box;
  display: block;
  position: relative;
  max-width: 100%;
  min-height: 40px;
  text-align: left;
  color: $select__font--bg-light;
}
.multiselect * {
  box-sizing: border-box;
}
.multiselect:focus {
  outline: none;
}
.multiselect--disabled {
  background: #ededed;
  pointer-events: none;
  opacity: 0.6;
}
.multiselect--read-only {
  background: white;
  pointer-events: none;
  opacity: unset;
}
.multiselect--active {
  z-index: 50;
}
.multiselect--active:not(.multiselect--above) .multiselect__current,
.multiselect--active:not(.multiselect--above) .multiselect__input,
.multiselect--active:not(.multiselect--above) .multiselect__tags {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.multiselect--active .multiselect__select {
  transform: rotate(180deg);
}
.multiselect--above.multiselect--active .multiselect__current,
.multiselect--above.multiselect--active .multiselect__input,
.multiselect--above.multiselect--active .multiselect__tags {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.multiselect__input,
.multiselect__single {
  position: relative;
  display: inline-block;
  min-height: 20px;
  line-height: 20px;
  border: none;
  border-radius: 5px;
  background: #fff;
  padding: 0 0 0 5px;
  width: 100%;
  transition: border 0.1s ease;
  box-sizing: border-box;
  margin-bottom: 8px;
  vertical-align: top;
}
.multiselect__input:-ms-input-placeholder {
  color: $select__font--bg-light;
}
.multiselect__input::placeholder {
  color: $select__font--bg-light;
}
.multiselect__tag ~ .multiselect__input,
.multiselect__tag ~ .multiselect__single {
  width: auto;
}
.multiselect__input:hover,
.multiselect__single:hover {
  border-color: #cfcfcf;
}
.multiselect__input:focus,
.multiselect__single:focus {
  border-color: #a8a8a8;
  outline: none;
  white-space: wrap;
}
.multiselect__single {
  padding-left: 5px;
  margin-bottom: 8px;
}
.multiselect__tags-wrap {
  display: inline;
}
.multiselect__tags {
  max-width: 100%;
  min-height: 40px;
  display: block;
  padding: 8px 40px 0 8px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  background: #fff;
  font-size: $select__tag--fontsize;
}
.multiselect__tag {
  position: relative;
  display: inline-block;
  padding: 4px 26px 4px 10px;
  border-radius: 5px;
  margin-right: 10px;
  color: #fff;
  line-height: 1;
  background: $select--main-color;
  margin-bottom: 5px;
  white-space: nowrap;
  overflow: hidden;
  max-width: 100%;
  text-overflow: ellipsis;
}
.multiselect__tag-icon {
  cursor: pointer;
  margin-left: 7px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  font-weight: 700;
  font-style: normal;
  width: 22px;
  text-align: center;
  line-height: 1.5;
  transition: all 0.2s ease;
  border-radius: 5px;
}
.multiselect__tag-icon:after {
  content: '\D7';
  color: $select__tag--icon;
  font-size: $select__tag--fontsize;
}
.multiselect__tag-icon:focus,
.multiselect__tag-icon:hover {
  background: $select__tag--icon-hover;
}
.multiselect__tag-icon:focus:after,
.multiselect__tag-icon:hover:after {
  color: #fff;
}
.multiselect__current {
  min-height: 40px;
  overflow: hidden;
  padding: 8px 30px 0 12px;
  white-space: nowrap;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
}
.multiselect__current,
.multiselect__select {
  line-height: 16px;
  box-sizing: border-box;
  display: block;
  margin: 0;
  text-decoration: none;
  cursor: pointer;
}
.multiselect__select {
  position: absolute;
  width: 40px;
  height: 38px;
  right: 1px;
  top: 1px;
  padding: 4px 8px;
  text-align: center;
  transition: transform 0.2s ease;
}
.multiselect__select:before {
  position: relative;
  right: 0;
  top: 65%;
  color: #999;
  margin-top: 4px;
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 0;
  content: '';
}
.multiselect__placeholder {
  color: $color__fontInputPlaceholder;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 2px;
}
.multiselect--active .multiselect__placeholder {
  display: none;
}
.multiselect__content-wrapper {
  position: absolute;
  display: block;
  background: #fff;
  width: 100%;
  max-height: 240px;
  overflow: auto;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  z-index: 50;
  -webkit-overflow-scrolling: touch;
}
.multiselect__content {
  list-style: none;
  display: inline-block;
  padding: 0;
  margin: 0;
  min-width: 100%;
  vertical-align: top;
}
.multiselect--above .multiselect__content-wrapper {
  bottom: 100%;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom: none;
  border-top: 1px solid #e8e8e8;
}
.multiselect__content::webkit-scrollbar {
  display: none;
}
.multiselect__element {
  display: block;
}
.multiselect__option {
  display: block;
  padding: 12px;
  min-height: 40px;
  line-height: 16px;
  text-decoration: none;
  text-transform: none;
  vertical-align: middle;
  position: relative;
  cursor: pointer;
}
.multiselect__option:after {
  top: 0;
  right: 0;
  position: absolute;
  line-height: 40px;
  padding-right: 12px;
  padding-left: 20px;
  font-size: 13px;
}
.multiselect__option--highlight {
  background: $select--main-color;
  outline: none;
  color: $select__font--highlight;
}
.multiselect__option--highlight:after {
  content: attr(data-select);
  background: $select--main-color;
  color: $select__font--highlight;
}
.multiselect__option--selected {
  background: $select__bg--selected;
  color: $select__font--bg-light;
  font-weight: 700;
}
.multiselect__option--selected:after {
  content: 'X';
  color: $color--error;
}
.multiselect__content--non-empty .multiselect__option--selected:after {
  content: '';
}
.multiselect__option--selected.multiselect__option--highlight {
  background: $select--highlight;
  color: $select__font--highlight;
}
.multiselect__option--selected.multiselect__option--highlight:after {
  background: $select--highlight;
  content: 'X';
  color: $select__font--highlight;
}
.multiselect__content--non-empty
  .multiselect__option--selected.multiselect__option--highlight:after {
  content: '';
}
.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: #ededed;
  color: #a6a6a6;
}
.multiselect--read-only .multiselect__current,
.multiselect--read-only .multiselect__select {
  visibility: hidden;
}
.multiselect__option--disabled {
  background: #ededed !important;
  color: #a6a6a6 !important;
  pointer-events: none;
}
.multiselect__option--group {
  // background: $select--;
  color: $select--main-color;
}
.multiselect__option--group.multiselect__option--disabled {
  background: #fff !important;
  color: $color__primary--dark !important;
  font-weight: bolder;

  // &:before {
  //   // @extend .fas;
  //   content: '\f0d7';
  //   transform: scale(1.6);
  //   padding-right: 6px;
  // }
}
.multiselect__option--group.multiselect__option--highlight {
  background: $select__font--bg-light;
  color: $select__font--highlight;
}
.multiselect__option--group.multiselect__option--highlight:after {
  background: $select__font--bg-light;
}
.multiselect__option--disabled.multiselect__option--highlight {
  background: #dedede;
}
.multiselect__option--group-selected.multiselect__option--highlight {
  background: $select--highlight;
  color: $select__font--highlight;
}
.multiselect__option--group-selected.multiselect__option--highlight:after {
  background: $select--highlight;
  content: attr(data-deselect);
  color: $select__font--highlight;
}
.multiselect-enter-active,
.multiselect-leave-active {
  transition: all 0.15s ease;
}
.multiselect-enter,
.multiselect-leave-active {
  opacity: 0;
}
.multiselect__strong {
  margin-bottom: 8px;
  line-height: 20px;
  display: inline-block;
  vertical-align: top;
}
[dir='rtl'] .multiselect {
  text-align: right;
}
[dir='rtl'] .multiselect__select {
  right: auto;
  left: 1px;
}
[dir='rtl'] .multiselect__tags {
  padding: 8px 8px 0 40px;
}
[dir='rtl'] .multiselect__content {
  text-align: right;
}
[dir='rtl'] .multiselect__option:after {
  right: auto;
  left: 0;
}
[dir='rtl'] .multiselect__clear {
  right: auto;
  left: 12px;
}
[dir='rtl'] .multiselect__spinner {
  right: auto;
  left: 1px;
}
.mutliselect--columns-selection {
  .multiselect__tags-wrap {
    display: none;
  }
  .multiselect__option {
    &:before {
      // @extend .far;
      content: '\f0c8';
      padding-right: 0.5em;
      color: $color__primary--dark;
    }
    &--selected {
      background: white;
      font-weight: 400;
      &:after {
        content: '';
      }

      &:before {
        // @extend .far;
        content: '\f14a';
        color: $color__primary--dark;
      }
    }
  }
  .multiselect__option--highlight {
    background: white;
    color: $select__font--bg-light;
  }
}
.mutliselect--columns-selection {
  width: 300px;
  margin: 0 0 10px 20px;
}
.multiselect__nested-options {
  .multiselect__tags {
    .multiselect__placeholder {
      color: var(--color__font--light);
    }
  }
}
