@use "node_modules/vue-multiselect/dist/vue-multiselect.min.css";
@use "./vuemultiselect";

$primary: #007bff;

.text-fail {
    color: #868686;
}

a {
    color: $primary;
}

.navbar-light .navbar-nav {
    .active {
        .nav-link {
            color: $primary;

            &:hover {
                color: lighten(#00a1e0, 10%);
            }
        }
    }

    .nav-link {
        color: #000000;
    }
}

#projectTable a.deployment-link {
    border-right: 1px solid #ccc;
    padding-right: 0.25rem;

    &:last-child {
        border: none;
    }
}

.btn-npm {
    background-color: #3c873a;
    border-color: #3c873a;
    color: #ffffff;

    &:hover {
        background-color: #468744;
        border-color: #468744;
        color: #ffffff;
    }
}

.btn-fontawesomefree {
    background-color: #ffffff;
    border-color: #146EBA;
    color: #146EBA;

    &:hover {
        background-color: #ffffff;
        border-color: lighten(#146EBA, 10%);
        color: lighten(#146EBA, 10%);
    }
}

.btn-fontawesomepro {
    background-color: #146EBA;
    border-color: #146EBA;
    color: #ffffff;

    &:hover {
        background-color: lighten(#146EBA, 10%);
        border-color: lighten(#146EBA, 10%);
        color: #ffffff;
    }
}

.btn-php {
    background-color: #777bb3;
    border-color: #777bb3;
    color: #ffffff;

    &:hover {
        background-color: #8386b5;
        border-color: #8386b5;
        color: #ffffff;
    }
}

.btn-gitlab {
    background-color: #6f42c1;
    border-color: #6f42c1;
    color: #ffffff;

    &:hover {
        background-color: #754cc1;
        border-color: #754cc1;
        color: #ffffff;
    }
}

.btn-gitea {
    background-color: #609925;
    border-color: #609925;
    color: #ffffff;

    &:hover {
        background-color: #74ba2b;
        border-color: #74ba2b;
        color: #ffffff;
    }
}

.btn-laravel {
    background-color: #eb4432;
    border-color: #ea4f3e;
    color: #ffffff;

    &:hover {
        background-color: #ea4f3e;
        border-color: #ea4f3e;
        color: #ffffff;
    }
}

.bg-laravel {
    background-color: #eb4432;
}
